import React, { useEffect, useRef } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

interface Notification {
  message: string;
  options: {
    key: string;
    variant?: string;
    action?: React.ReactNode;
    persist?: boolean;
  };
}

interface RootState {
  utilsReducer: {
    notifications: Notification[];
  };
}

const Notifier: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: RootState) => state.utilsReducer.notifications
  );
  const { enqueueSnackbar } = useSnackbar();
  const displayed = useRef<string[]>([]);

  useEffect(() => {
    notifications.forEach((notification) => {
      // If notification already displayed, abort
      if (displayed.current.includes(notification.options.key)) {
        return;
      }

      // Display notification using enqueueSnackbar
      enqueueSnackbar(notification.message, {
        variant: (notification.options.variant as VariantType) || undefined,
        action: notification.options.action || undefined,
        persist: notification.options.persist || false,
      });

      // Add notification's key to the displayed ref
      displayed.current.push(notification.options.key);

      // Dispatch action to remove the notification from the Redux store
      dispatch({
        type: 'REMOVE_NOTIFICATION',
        key: notification.options.key,
      });
    });
  }, [notifications]);

  return null;
};

export default Notifier;
