/* eslint-disable */

import moment from 'moment';
import { apiPost, apiGet } from './UtilsActions';
import { handleErrors } from './ErrorActions';
import { fetchProductDataForMonth } from './AssetsProducingActions';
import { uploadOperationsInBulk } from './AdministrationActions';

import { enums } from '../enums';
import { AppDispatch } from 'redux/store';
import {
  OperationAllocateBodyType,
  OperationCertificateBodyType,
} from './types';

export const setFromStartButtonPressed = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'FROM_START_BUTTON_PRESSED' });
};

export const setCustomButtonPressed = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'CUSTOM_BUTTON_PRESSED' });
};

export const displayDateFilter = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'DISPLAY_DATE_FILTER' });
};

export const hideDateFilter = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'HIDE_DATE_FILTER' });
};

export const toggleStartDateDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'TOGGLE_START_DATE_DIALOG' });
};

export const toggleEndDateDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'TOGGLE_END_DATE_DIALOG' });
};

export const getFirstTransactionAssociatedWithUserOrganization_Date =
  async () => {
    try {
      const GET_FIRST_TRANSACTION_ASSOCIATED_WITH_USER_ORGANIZATION_URL = `${process.env.REACT_APP_API_URL}/api/transactions/first`;
      let rawReponse = await fetch(
        GET_FIRST_TRANSACTION_ASSOCIATED_WITH_USER_ORGANIZATION_URL,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      );

      if (!rawReponse.ok) {
        throw new Error(rawReponse.statusText);
      }

      let firstTransactionAssociatedWithUserOrganization;
      try {
        firstTransactionAssociatedWithUserOrganization =
          await rawReponse.json();
      } catch (err) {
        firstTransactionAssociatedWithUserOrganization = {
          year: 2019,
          month: '01',
        };
      }
      return moment(
        `${firstTransactionAssociatedWithUserOrganization.year}-${String(
          firstTransactionAssociatedWithUserOrganization.month
        ).padStart(2, '0')}-01`
      ).format('YYYY-MM-DD');
    } catch (error) {
      console.error(error);
      //If first transaction could not be retrieved, consider returned date to be "2019-01-01"
      return '2019-01-01';
    }
  };

export const getBalancesStart =
  (dateStart: string, dateEnd: string, page?: number, pageSize?: number) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'GET_BALANCES_START' });

    return await fetch(
      `${process.env.REACT_APP_API_URL}/api/tokens/${dateStart}/${dateEnd}?page=${page}&limit=${pageSize}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    )
      .then(handleErrors)
      .then(async (res) => {
        return res.json();
      })
      .then(async ({ filtredCachedBalances, totalCount }) => {
        let sortedBalancesByDatesDesc = sortBalancesByDatesDesc(
          filtredCachedBalances
        );
        let dynamicBalances = [];
        for (const b of sortedBalancesByDatesDesc) {
          let balanceToPush = { ...b };
          let currentOrigin = b?._origin;
          let productStandard = currentOrigin.standard;
          //Let's check if dynamic datas are set on product's standard
          let dynamicDatas = productStandard?.infoTexts.filter(
            (infoX: any) => infoX.type === 'dynamicData'
          );

          if (dynamicDatas && dynamicDatas.length > 0) {
            let dataForMonth = await fetchProductDataForMonth(
              currentOrigin?._id,
              b._year,
              b._month
            );
            let dataForSourceArray = dataForMonth?.data;

            for (const dynamicData of dynamicDatas) {
              let dynamicDataSourceName = dynamicData?.sourceName;
              let infoX = dynamicData?.infoProp;

              if (dynamicDataSourceName) {
                let dataForSource: any;

                if (dataForSourceArray && Array.isArray(dataForSourceArray)) {
                  dataForSource = dataForSourceArray?.find(
                    (d) => d.source === dynamicDataSourceName
                  );
                }
                currentOrigin.standardValues = currentOrigin.standardValues.map(
                  ({ key, value }: any) => {
                    if (key === infoX) {
                      return { key, value: dataForSource?.measure || value };
                    }
                    return { key, value };
                  }
                );
              }
            }
            balanceToPush._origin = currentOrigin;
          }
          dynamicBalances.push(balanceToPush);
        }

        dispatch({ type: 'GET_BALANCES_SUCCESS', payload: dynamicBalances });
        if (dynamicBalances.length > 100)
          dispatch({ type: 'SET_PAGE_SIZE', payload: dynamicBalances.length });
        dispatch({
          type: 'GET_BALANCES_TOTAL_COUNT_SUCCESS',
          payload: totalCount,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'GET_BALANCES_FAIL' });
      });
  };
export const getBalancesStartByStandard =
  (
    standardId: string = 'allStandards',
    dateStart: string,
    dateEnd: string,
    page?: number,
    pageSize?: number
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'GET_BALANCES_START' });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tokens/${standardId}/${dateStart}/${dateEnd}?page=${page}&limit=${pageSize}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const {
        filtredCachedBalances,
        paginatedBalancesCount,
        allBalancesCount,
      } = await response.json();

      dispatch({
        type: 'GET_BALANCES_SUCCESS',
        payload: filtredCachedBalances,
      });

      dispatch({
        type: 'GET_BALANCES_TOTAL_COUNT_SUCCESS',
        payload: paginatedBalancesCount,
      });
      dispatch({
        type: 'GET_ALL_BALANCES_TOTAL_COUNT_SUCCESS',
        payload: allBalancesCount,
      });
    } catch (error) {
      console.error('Failed to fetch balances:', error);
      dispatch({ type: 'GET_BALANCES_FAIL' });
    }
  };

export const getDefaultBalancesPaginationThreshold =
  () => async (dispatch: AppDispatch) => {
    try {
      const response = await apiGet('/api/tokens/pagination/threshold');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const { balancesThreshold } = await response.json();
      dispatch(getDefaultBalancesPaginationThresholdSuccess(balancesThreshold));
    } catch (error) {
      console.error(error);
    }
  };

export const getDefaultBalancesPaginationThresholdSuccess =
  (threshold: number) => (dispatch: AppDispatch) => {
    dispatch({
      type: 'SET_DEFAULT_BALANCES_PAGINATION_THRESHOLD',
      payload: threshold,
    });
  };

export const setStartDate = (startDate: any) => (dispatch: AppDispatch) => {
  let formattedStartDate = moment(
    startDate['$d'] ? startDate['$d'] : startDate
  ).format('YYYY-MM-DD');
  dispatch({ type: 'SET_START_DATE', payload: formattedStartDate });
};

export const setEndDate = (endDate: any) => (dispatch: AppDispatch) => {
  let formattedEndDate = moment(endDate['$d'] ? endDate['$d'] : endDate).format(
    'YYYY-MM-DD'
  );
  dispatch({ type: 'SET_END_DATE', payload: formattedEndDate });
};

export const transferToChanged = (to: any) => (dispatch: AppDispatch) => {
  dispatch({ type: 'TRANSFER_TO_CHANGED', payload: to });
};

export const transferVolumeChanged =
  (volume: number) => (dispatch: AppDispatch) => {
    dispatch({ type: 'TRANSFER_VOLUME_CHANGED', payload: volume });
  };

export const openTransferFormDialog =
  (
    _origin: any,
    _from: any,
    _to: any,
    _year: any,
    _month: any,
    _quantity: number
  ) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: 'OPEN_TRANSFER_CERTIFICATES_FORM' });
    dispatch({ type: 'TRANSFER_ORIGIN_CHANGED', payload: _origin });
    dispatch({ type: 'TRANSFER_FROM_CHANGED', payload: _from });
    dispatch({ type: 'TRANSFER_TO_CHANGED', payload: _to });
    dispatch({ type: 'TRANSFER_YEAR_CHANGED', payload: _year });
    dispatch({ type: 'TRANSFER_MONTH_CHANGED', payload: _month });
    dispatch({ type: 'TRANSFER_VOLUME_CHANGED', payload: _quantity });
  };
export const setSelectedBalanceData =
  (_origin: any, _from: any, _year: any, _month: any, _quantity: any) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: 'SET_SELECTED_BALANCE_DATA',
      payload: { _origin, _from, _year, _month, _quantity },
    });
  };
export const closeTransferFormDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'CLOSE_TRANSFER_CERTIFICATES_FORM' });
};

export const displayWarningInDialog =
  (_transferCertificatesData: any[]) => (dispatch: AppDispatch) => {
    dispatch({
      type: 'DISPLAY_WARNING_TRANSFER_CERTIFICATES',
      payload: _transferCertificatesData,
    });
  };

export const displayFormInDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'DISPLAY_FORM_TRANSFER_CERTIFICATES' });
};

export const transferCertificate = async (
  transferBody: OperationCertificateBodyType
) => {
  const {
    _origin: productId,
    _year,
    _month,
    _from: from,
    _to: to,
    _volume: value,
    _unit: unit,
  } = transferBody;

  const month = parseInt(_month, 10) > 9 ? _month : '0' + _month;
  const year = parseInt(_year, 10);
  const period = `${year}-${month}`;

  const transferMultipleBody = {
    type: 'transfer',
    productId: productId,
    period: period,
    unit: unit,
    value: value,
    from: from,
    to: to,
  };

  return await uploadOperationsInBulk([transferMultipleBody]);
};

export const getBalanceOpenQuantity = async ({
  origin,
  organizationIdentifier,
  year,
  month,
}: {
  origin: string;
  organizationIdentifier: string;
  year: number;
  month: number;
}) => {
  let fromOrganizationUpdatedBalance = null;
  let getFromOrganizationBalanceResponse = await apiGet(
    `/api/tokens/balance/${origin}/${organizationIdentifier}/${year}/${month}`
  );

  if (
    getFromOrganizationBalanceResponse.status >= 200 &&
    getFromOrganizationBalanceResponse.status <= 299
  ) {
    fromOrganizationUpdatedBalance =
      await getFromOrganizationBalanceResponse.json();
  }

  return fromOrganizationUpdatedBalance;
};

export const getBalanceRetiredQuantity = async ({
  origin,
  organizationMongoId,
  year,
  month,
}: {
  origin: string;
  organizationMongoId: string;
  year: number;
  month: number;
}) => {
  let fromOrganizationAllocatedQuantity = null;
  let getFromOrganizationAllocatedQuantityResponse = await apiGet(
    `/api/tokens/allocated/${origin}/${organizationMongoId}/${year}/${month}`
  );

  if (
    getFromOrganizationAllocatedQuantityResponse.status >= 200 &&
    getFromOrganizationAllocatedQuantityResponse.status <= 299
  ) {
    fromOrganizationAllocatedQuantity =
      await getFromOrganizationAllocatedQuantityResponse.json();
  }

  return fromOrganizationAllocatedQuantity;
};

const sortBalancesByDatesDesc = (balances: any[]) => {
  return balances.sort((a, b) => {
    let aTimestampMonth = moment(a._year + '-' + a._month, 'YYYY-MM').format(
      'X'
    );
    let bTimestampMonth = moment(b._year + '-' + b._month, 'YYYY-MM').format(
      'X'
    );

    if (aTimestampMonth < bTimestampMonth) {
      return 1;
    } else if (bTimestampMonth < aTimestampMonth) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const getBalanceListAfterOperation = ({
  balances,
  operationBody,
  fromBalanceQuantity,
  toBalanceQuantity,
  fromBalanceAllocatedQuantity,
}: {
  balances: any[];
  operationBody: any;
  fromBalanceQuantity: number;
  toBalanceQuantity?: number;
  fromBalanceAllocatedQuantity?: number;
}) => {
  let newBalances = [];

  const period = operationBody.period;
  operationBody.year = period.split('-')[0];
  operationBody.month = period.split('-')[1];
  for (let balance of balances) {
    if (
      balance._origin.assetID === operationBody.asset_id &&
      parseInt(balance._year) === parseInt(operationBody.year) &&
      parseInt(balance._month) === parseInt(operationBody.month) &&
      balance._from.identifier === operationBody.from
    ) {
      if (!isNaN(fromBalanceQuantity as number)) {
        balance._quantity = fromBalanceQuantity;
      }
      if (!isNaN(fromBalanceAllocatedQuantity as number)) {
        balance._allocatedQuantity = fromBalanceAllocatedQuantity;
      }
    }

    if (
      balance._origin.assetID === operationBody.asset_id &&
      parseInt(balance._year) === parseInt(operationBody.year) &&
      parseInt(balance._month) === parseInt(operationBody.month) &&
      balance._from.identifier === operationBody._to
    ) {
      if (!isNaN(toBalanceQuantity as number)) {
        balance._quantity = toBalanceQuantity;
      }
    }

    newBalances.push(balance);
  }

  //sort balances
  return sortBalancesByDatesDesc(newBalances);
};

export const transferCertificatesStart =
  (
    _origin: any,
    _year: any,
    _month: any,
    _from: any,
    _to: any,
    _volume: any,
    balances: any[],
    userIsSuperAdmin: boolean
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'TRANSFERING_CERTIFICATES_START' });

    let socketId = localStorage.getItem('socketId');

    let transferBody: OperationCertificateBodyType = {
      socketId: socketId,
      _origin: _origin.assetID,
      _year: parseInt(_year, 10),
      _month: parseInt(_month, 10),
      _from: _from.identifier,
      _to: _to.identifier,
      _unit: _origin.unit,
      _volume: _volume,
    };
    let transferResult = await transferCertificate(transferBody).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'TRANSFERING_CERTIFICATES_FAIL' });
      }
    );

    if (transferResult && transferResult.error) {
      //Catch form error
      dispatch({ type: 'DISPLAY_FORM_TRANSFER_CERTIFICATES' });
      dispatch({ type: 'TRANSFERING_CERTIFICATES_FAIL' });
      dispatch({
        type: 'TRANSFER_CERTIFICATES_FORM_ERROR',
        payload: transferResult.error,
      });
    } else if (transferResult) {
      //No error
    }
  };

export const openAllocateFormDialog =
  (
    _origin: any,
    _from: any,
    _to: any,
    _year: any,
    _month: any,
    _quantityInKwhOrMMBTU: number
  ) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: 'OPEN_ALLOCATE_CERTIFICATES_FORM' });
    dispatch({ type: 'ALLOCATE_ORIGIN_CHANGED', payload: _origin });
    dispatch({ type: 'ALLOCATE_FROM_CHANGED', payload: _from });
    dispatch({ type: 'ALLOCATE_TO_CHANGED', payload: _to });
    dispatch({ type: 'ALLOCATE_YEAR_CHANGED', payload: _year });
    dispatch({ type: 'ALLOCATE_MONTH_CHANGED', payload: _month });
    dispatch({
      type: 'ALLOCATE_VOLUME_CHANGED',
      payload: _quantityInKwhOrMMBTU,
    });
  };

export const closeAllocateFormDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'CLOSE_ALLOCATE_CERTIFICATES_FORM' });
};

export const setBalanceStandard =
  (standardId: string) => (dispatch: AppDispatch) => {
    if (standardId === `allStandards`) {
      dispatch({ type: 'SET_BALANCE_STANDARD', payload: undefined });
    } else {
      dispatch({ type: 'SET_BALANCE_STANDARD', payload: standardId });
    }
  };

export const setBalanceProductType =
  (productTypeId: string) => (dispatch: AppDispatch) => {
    if (productTypeId === `allProductTypes`) {
      dispatch({ type: 'SET_BALANCE_PRODUCT_TYPE', payload: undefined });
    } else {
      dispatch({ type: 'SET_BALANCE_PRODUCT_TYPE', payload: productTypeId });
    }
  };

export const setBalanceStandardType =
  (standardType: string) => (dispatch: AppDispatch) => {
    if (standardType === `allStandards`) {
      dispatch({ type: 'SET_BALANCE_STANDARD_TYPE', payload: undefined });
    } else {
      dispatch({ type: 'SET_BALANCE_STANDARD_TYPE', payload: standardType });
    }
  };

export const hideProductDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'HIDE_PRODUCT_DIALOG' });
};

export const openProductDialog =
  (product: any, _month?: any, _year?: any) => (dispatch: AppDispatch) => {
    dispatch({ type: 'OPEN_PRODUCT_DIALOG' });
    dispatch({
      type: 'GET_PRODUCT_SUCCESS',
      payload: { ...product, balancePeriod: `${_month}-${_year}` },
    });
  };

export const displayAllocateWarningInDialog =
  (_allocateCertificatesData: any) => (dispatch: AppDispatch) => {
    dispatch({
      type: 'DISPLAY_WARNING_ALLOCATE_CERTIFICATES',
      payload: _allocateCertificatesData,
    });
  };

export const displayAllocateFormInDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'DISPLAY_FORM_ALLOCATE_CERTIFICATES' });
};

export const allocateCertificate = async (
  allocateBody: OperationAllocateBodyType
) => {
  const {
    _origin: productId,
    _year,
    _month,
    _from: from,
    _to: to,
    _volume: value,
    _unit: unit,
  } = allocateBody;

  const month = parseInt(_month, 10) > 9 ? _month : '0' + _month;
  const year = parseInt(_year, 10);
  const period = `${year}-${month}`;

  const retireMultipleBody = {
    type: 'retire',
    productId: productId,
    period: period,
    unit: unit,
    value: value,
    from: from,
    to: to,
  };

  return await uploadOperationsInBulk([retireMultipleBody]);
};

export const allocateCertificatesStart =
  (
    _origin: any,
    _year: any,
    _month: any,
    _from: any,
    _to: any,
    _volume: any,
    _showRetailerOnPdfCertificate: boolean,
    balances: any[]
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'ALLOCATE_CERTIFICATES_START' });

    let socketId = localStorage.getItem('socketId');

    let allocateBody: OperationAllocateBodyType = {
      socketId: socketId,
      _origin: _origin.assetID,
      _unit: _origin.unit,
      _year: parseInt(_year, 10),
      _month: parseInt(_month, 10),
      _issuer: _origin.organization.identifier,
      _from: _from.identifier,
      _to: _to.identifier,
      _volume: _volume,
      _showRetailerOnPdfCertificate,
    };

    let allocateResult = await allocateCertificate(allocateBody).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'ALLOCATE_CERTIFICATES_FAIL' });
      }
    );

    if (allocateResult && allocateResult.error) {
      //Catch form error
      dispatch({ type: 'DISPLAY_FORM_ALLOCATE_CERTIFICATES' });
      dispatch({ type: 'ALLOCATE_CERTIFICATES_FAIL' });
      dispatch({
        type: 'ALLOCATE_CERTIFICATES_FORM_ERROR',
        payload: allocateResult.error,
      });
    } else if (allocateResult) {
      //No error
    }
  };

export const resetPageSize = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'RESET_PAGE_SIZE' });
};

export const handlePageSizeChange =
  (size: number) => (dispatch: AppDispatch) => {
    dispatch({ type: 'SET_PAGE_SIZE', payload: size });
  };
