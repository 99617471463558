import { useEffect, useState } from 'react';
import { Differ, DiffResult } from 'json-diff-kit';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { apiGet, openErrorSnack } from 'actions';
import useProductSlices from 'components/Product/Redux/Slices/useProductSlices';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';

const useSummaryPreviewJSONModalConfig = () => {
  const dispatch: AppDispatch = useDispatch();
  const { currentProduct, productHistoryInJSON } = useProductSlicesCombiner();
  const {
    selectedCurrentProductVersionIndex,
    selectedPreviousProductVersionIndex,
  } = useProductSlices();
  const [currentJSON, setCurrentJSON] = useState<object | null>(null);
  const [previousJSON, setPreviousJSON] = useState<object | null>(null);
  const [diffResult, setDiffResult] = useState<
    [DiffResult[], DiffResult[]] | null
  >(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchJSONContent = async () => {
      if (
        !currentProduct ||
        !productHistoryInJSON ||
        productHistoryInJSON.length === 0
      )
        return;

      setLoading(true);

      try {
        const effectiveCurrentIndex =
          selectedCurrentProductVersionIndex ?? productHistoryInJSON.length - 1;
        const effectivePreviousIndex =
          selectedPreviousProductVersionIndex ??
          productHistoryInJSON.length - 2;

        if (
          effectiveCurrentIndex >= 0 &&
          effectiveCurrentIndex < productHistoryInJSON.length
        ) {
          const currentRes = await apiGet(
            `/api/fields/s3/${currentProduct.productId}/${productHistoryInJSON[effectiveCurrentIndex].hash}`
          );
          const currentData = await currentRes.json();
          if (currentData.success) setCurrentJSON(currentData.data);
        }

        if (
          effectivePreviousIndex >= 0 &&
          effectivePreviousIndex < productHistoryInJSON.length
        ) {
          const previousRes = await apiGet(
            `/api/fields/s3/${currentProduct.productId}/${productHistoryInJSON[effectivePreviousIndex].hash}`
          );
          const previousData = await previousRes.json();
          if (previousData.success) setPreviousJSON(previousData.data);
        }
      } catch (error) {
        dispatch(
          openErrorSnack('Retreiving product JSON has failed: ' + error)
        );
      } finally {
        setLoading(false);
      }
    };

    fetchJSONContent();
  }, [
    currentProduct,
    productHistoryInJSON,
    selectedCurrentProductVersionIndex,
    selectedPreviousProductVersionIndex,
  ]);

  useEffect(() => {
    if (currentJSON && previousJSON) {
      const differ = new Differ({
        detectCircular: true,
        maxDepth: Infinity,
        showModifications: true,
        arrayDiffMethod: 'normal',
      });
      const diff = differ.diff(previousJSON, currentJSON);
      setDiffResult(diff.length > 0 ? [...diff] : null);
    }
  }, [currentJSON, previousJSON]);

  return {
    currentJSON,
    previousJSON,
    diffResult,
    loading,
  };
};

export default useSummaryPreviewJSONModalConfig;
