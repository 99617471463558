import { getOpenSnackbarButton } from 'components/commons/Snackbar/SnackbarOpenButton';

import { bulkOperationsDatasetChanged } from './AdministrationActions';
import { openInfoSnack, openErrorSnack } from './UtilsActions';

export async function loadDataset({
  datasetFile,
  uploadFunction,
  datasetIdentifier,
  dispatch,
  resolve,
}: {
  datasetFile: any;
  uploadFunction: any;
  datasetIdentifier: string;
  dispatch: any;
  resolve: any;
}) {
  try {
    const uploadDatasetResult = await uploadFunction(datasetFile);
    dispatch({ type: `UPLOAD_${datasetIdentifier}_SUCCESS` });
    dispatch(
      openInfoSnack('', {
        persist: true,
        action: () =>
          getOpenSnackbarButton({
            path: `/operations/feed/${uploadDatasetResult.operationId}`,
            text: 'Processing dataset.. Click to open details',
          }),
      })
    );
    resolve(uploadDatasetResult);
    dispatch(bulkOperationsDatasetChanged(null));
  } catch (error: any) {
    console.error(error.message);
    dispatch(openErrorSnack(`Bulk operations fail : ${error.message}`));
    dispatch({ type: `UPLOAD_${datasetIdentifier}_FAIL` });
  }
}
