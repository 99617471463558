/* eslint-disable */

import { AppDispatch } from 'redux/store';
import {
  apiGet,
  apiPost,
  openErrorSnack,
  openSuccessSnack,
} from './UtilsActions';
import { SnackbarCloseButton } from 'components/commons/Snackbar/SnackbarCloseButton';

export const getNominationsRequestsStart =
  (organizationId: string, year: string) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'GET_NOMINATIONS_REQUESTS_START' });

    let responseFlex = await apiGet(
      `/api/nominations/${organizationId}/${year}`
    );

    if (responseFlex.status >= 200 && responseFlex.status <= 299) {
      const flex = await responseFlex.json();
      dispatch({ type: 'GET_NOMINATIONS_REQUESTS_SUCCESS', payload: flex });
    } else {
      // Handle errors
      try {
        let jsonError = await responseFlex.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      }
    }
  };

export const getIssuerOrganizations =
  (organization: any, isUserIndependant?: boolean) =>
  (dispatch: AppDispatch) => {
    let issuerOrganizations: any[] = organization?.issuerOrganizations || [];
    /** when connected as an independant user,
     *  we should append userOrganization to the list of 'seller organizations' (here organization == userOrganization),
     * so that we can retrieve the nominations of business organizations that have nominated against userOrganization
     * */
    if (
      isUserIndependant &&
      !issuerOrganizations.some(
        (element: any) => element._id === organization._id
      )
    ) {
      issuerOrganizations.push(organization);
    }
    issuerOrganizations.length > 0 &&
      dispatch({
        type: 'GET_ISSUER_ORGANIZATIONS_SUCCESS',
        payload: issuerOrganizations,
      });

    return issuerOrganizations;
  };

export const getNominationsOfIssuerOrganizationByYear =
  (organizationId: string, issuerOrganizationId: string, year: string) =>
  async (dispatch: AppDispatch) => {
    let response = await apiGet(
      `/api/nominations/${organizationId}/${issuerOrganizationId}/${year}`
    );
    if (response.status >= 200 && response.status <= 299) {
      const nominationsIssuerOganizations = await response.json();
      dispatch({
        type: 'GET_NOMINATIONS_REQUESTS_SUCCESS',
        payload: nominationsIssuerOganizations,
      });

      dispatch({
        type: 'GET_NOMINATIONS_ISSUER_ORGANIZATIONS',
        payload: nominationsIssuerOganizations,
      });
    } else {
      // Handle errors
      try {
        let jsonError = await response.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
      }
    }
  };

export const getNominationsIndependantRequestsStart =
  (organizationId: string, year: string) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'GET_NOMINATIONS_REQUESTS_START' });
    dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_START' });

    let independantWithNominationsFeature = [];
    let response = await apiGet('/api/organizations/having-nominations');

    if (response.status >= 200 && response.status <= 299) {
      independantWithNominationsFeature = await response.json();

      dispatch({
        type: 'GET_NOMINATIONS_INDEPENDANTS_SUCCESS',
        payload: independantWithNominationsFeature,
      });
      dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
    } else {
      // Handle errors
      try {
        let jsonError = await response.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
      }
    }

    let defaultOrganizationId = organizationId
      ? organizationId
      : independantWithNominationsFeature[0]?._id;
    let defaultOrganization = independantWithNominationsFeature.find(
      (org: any) => org._id === defaultOrganizationId
    );
    dispatch({
      type: 'NOMINATIONS_ORGANIZATION_CHANGED',
      payload: defaultOrganization,
    });
    let responseFlex = await apiGet(
      `/api/nominations/${defaultOrganizationId}/${year}`
    );

    if (responseFlex.status >= 200 && responseFlex.status <= 299) {
      const flex = await responseFlex.json();
      dispatch({ type: 'GET_NOMINATIONS_REQUESTS_SUCCESS', payload: flex });
    } else {
      // Handle errors
      try {
        let jsonError = await responseFlex.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      }
    }
  };

export const getIssuerNominationsRequestsStart =
  (organizationId: string, year: string) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'GET_NOMINATIONS_REQUESTS_START' });
    dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_START' });

    let independantsWithNominationsFeature = [];
    let response = await apiGet('/api/organizations/having-nominations');

    if (response.status >= 200 && response.status <= 299) {
      independantsWithNominationsFeature = await response.json();
      dispatch({
        type: 'GET_NOMINATIONS_INDEPENDANTS_SUCCESS',
        payload: independantsWithNominationsFeature,
      });
      dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
    } else {
      // Handle errors
      try {
        let jsonError = await response.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
      }
    }

    let selectedOrganizationId = organizationId
      ? organizationId
      : independantsWithNominationsFeature.length > 0
      ? independantsWithNominationsFeature[0]._id
      : undefined;
    if (selectedOrganizationId) {
      let selectedOrganization = independantsWithNominationsFeature.find(
        (org: any) => org._id === selectedOrganizationId
      );
      dispatch({
        type: 'NOMINATIONS_ORGANIZATION_CHANGED',
        payload: selectedOrganization,
      });
      let responseFlex = await apiGet(
        `/api/nominations/${selectedOrganizationId}/${year}`
      );

      if (responseFlex.status >= 200 && responseFlex.status <= 299) {
        const flex = await responseFlex.json();
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_SUCCESS', payload: flex });
      } else {
        // Handle errors
        try {
          let jsonError = await responseFlex.json();
          console.error(jsonError);
          const errorMessage = jsonError?.message;
          dispatch(openErrorSnack(errorMessage));
          dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
        } catch (error: any) {
          console.error(error);
          const errorMessage = error?.message;
          dispatch(openErrorSnack(errorMessage));
          dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
        }
      }
    }
  };

export const getIndependantWithNominationsFeature =
  (userOrga: any) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'GET_NOMINATIONS_REQUESTS_START' });
    dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_START' });

    let independantsWithNominationsFeature: any[] = [];
    const response = await apiGet('/api/organizations/having-nominations');

    if (response.status >= 200 && response.status <= 299) {
      independantsWithNominationsFeature = await response.json();
      independantsWithNominationsFeature =
        independantsWithNominationsFeature.filter(
          (n) => n._id !== userOrga?._id
        );
      dispatch({
        type: 'GET_NOMINATIONS_INDEPENDANTS_SUCCESS',
        payload: independantsWithNominationsFeature,
      });
    } else {
      try {
        const jsonError = await response.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
      }
    }
    dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
    return independantsWithNominationsFeature;
  };
export const getIssuerNominationsRequestsStartForSellerAndBuyer =
  (buyerOrganizationId: string, sellerOrganizationId: string, year: string) =>
  async (dispatch: AppDispatch) => {
    let responseFlex = await apiGet(
      `/api/nominations/${buyerOrganizationId}/${sellerOrganizationId}/${year}`
    );

    if (responseFlex.status >= 200 && responseFlex.status <= 299) {
      const flex = await responseFlex.json();
      dispatch({ type: 'GET_NOMINATIONS_REQUESTS_SUCCESS', payload: flex });
    } else {
      // Handle errors
      try {
        let jsonError = await responseFlex.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      }
    }
  };

export const getAdminNominationsRequestsStart =
  (organizationId: string | undefined, year: string) =>
  async (dispatch: AppDispatch): Promise<any[]> => {
    dispatch({ type: 'GET_NOMINATIONS_REQUESTS_START' });
    dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_START' });

    let independantsWithNominationsFeature: any[] = [];
    let response = await apiGet('/api/organizations/having-nominations');

    if (response.status >= 200 && response.status <= 299) {
      independantsWithNominationsFeature = await response.json();
      dispatch({
        type: 'GET_NOMINATIONS_INDEPENDANTS_SUCCESS',
        payload: independantsWithNominationsFeature,
      });
      dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
    } else {
      try {
        let jsonError = await response.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_ORGANIZATIONS_END' });
      }
    }

    let selectedOrganizationId = organizationId
      ? organizationId
      : independantsWithNominationsFeature.length > 0
      ? independantsWithNominationsFeature[0]._id
      : undefined;

    if (selectedOrganizationId) {
      let selectedOrganization = independantsWithNominationsFeature.find(
        (org: any) => org._id === selectedOrganizationId
      );
      dispatch({
        type: 'NOMINATIONS_ORGANIZATION_CHANGED',
        payload: selectedOrganization,
      });
      let responseFlex = await apiGet(
        `/api/nominations/${selectedOrganizationId}/${year}`
      );

      if (responseFlex.status >= 200 && responseFlex.status <= 299) {
        const flex = await responseFlex.json();
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_SUCCESS', payload: flex });
      } else {
        // Handle errors
        try {
          let jsonError = await responseFlex.json();
          console.error(jsonError);
          const errorMessage = jsonError?.message;
          dispatch(openErrorSnack(errorMessage));
          dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
        } catch (error: any) {
          console.error(error);
          const errorMessage = error?.message;
          dispatch(openErrorSnack(errorMessage));
          dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
        }
      }
    }
    return independantsWithNominationsFeature;
  };

export const getClickedNominationHistory =
  (nominationId: string, minMaxRequestedHistory: any) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'GET_NOMINATION_HISTORY_START' });
    let response = await apiGet('/api/nominations/history/' + nominationId);
    dispatch({
      type: 'NOMINATIONS_TOGGLE_HISTORY_DIALOG',
      payload: nominationId,
    });

    if (response.status >= 200 && response.status <= 299) {
      const clickedNominationHistoryArray = await response.json();
      dispatch({
        type: 'GET_NOMINATION_HISTORY_SUCCESS',
        payload: { clickedNominationHistoryArray, minMaxRequestedHistory },
      });
      dispatch({ type: 'GET_NOMINATION_HISTORY_END' });
    } else {
      // Handle errors
      try {
        let jsonError = await response.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATION_HISTORY_END' });
        dispatch({
          type: 'NOMINATIONS_TOGGLE_HISTORY_DIALOG',
          payload: nominationId,
        });
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATION_HISTORY_END' });
        dispatch({
          type: 'NOMINATIONS_TOGGLE_HISTORY_DIALOG',
          payload: nominationId,
        });
      }
    }
  };

export const flexBuyerOrganizationChanged =
  (organization: any) => (dispatch: AppDispatch) => {
    dispatch({
      type: 'NOMINATIONS_ORGANIZATION_CHANGED',
      payload: organization,
    });
  };
export const flexSellerOrganizationChanged =
  (organization: any) => (dispatch: AppDispatch) => {
    dispatch({
      type: 'NOMINATIONS_ISSUER_ORGANIZATION_CHANGED',
      payload: organization,
    });
  };

export const flexYearChanged = (year: string) => (dispatch: AppDispatch) => {
  dispatch({ type: 'NOMINATIONS_YEAR_CHANGED', payload: year });
};

export const toggleWarningDialog = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'NOMINATIONS_TOGGLE_WARNING_DIALOG' });
};

export const toggleHistoryDialog =
  (nominationId: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: 'NOMINATIONS_TOGGLE_HISTORY_DIALOG',
      payload: nominationId,
    });
  };

export const updateNominationsRequestsToDo =
  (nominationsRequestToDo: any[], minMaxRequested: string) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: 'UPDATE_NOMINATIONS_REQUEST_TO_DO',
      payload: { nominationsRequestToDo, minMaxRequested },
    });
  };

export const setNominationsRequestsStart =
  (
    nominationsRequests: any[],
    organizationId: string,
    issuerOrganizationId: string,
    year: string,
    organizationUnit: string
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'SET_NOMINATIONS_REQUESTS_START' });

    for (const nominationsRequest of nominationsRequests) {
      if (nominationsRequest._min)
        nominationsRequest.min = nominationsRequest._min;
      delete nominationsRequest._min;

      if (nominationsRequest._max)
        nominationsRequest.max = nominationsRequest._max;
      delete nominationsRequest._max;

      if (nominationsRequest._requested)
        nominationsRequest.requested = nominationsRequest._requested;
      delete nominationsRequest._requested;

      if (nominationsRequest._id)
        nominationsRequest.id = nominationsRequest._id;
      delete nominationsRequest._id;

      nominationsRequest.organization = organizationId;

      nominationsRequest.unit = organizationUnit;
      nominationsRequest.issuerOrganization = issuerOrganizationId;

      let response = await apiPost(`/api/nominations`, nominationsRequest);

      if (response.status >= 200 && response.status <= 299) {
        dispatch({ type: 'SET_NOMINATIONS_REQUESTS_START_SUCCESS' });
        dispatch(openSuccessSnack('Nomination successfully updated !'));
      } else {
        // Handle errors
        try {
          let jsonError = await response.json();
          console.error(jsonError);
          const errorMessage = jsonError?.message;
          dispatch(openErrorSnack(errorMessage));
          dispatch({ type: 'GET_NOMINATIONS_INDEPENDANTS_FAIL' });
        } catch (error: any) {
          console.error(error);
          const errorMessage = error?.message;
          dispatch(openErrorSnack(errorMessage));
          dispatch({ type: 'GET_NOMINATIONS_INDEPENDANTS_FAIL' });
        }
      }
    }

    dispatch({ type: 'GET_NOMINATIONS_REQUESTS_START' });

    let responseFlex = await apiGet(
      `/api/nominations/${organizationId}/${issuerOrganizationId}/${year}`
    );

    if (responseFlex.status >= 200 && responseFlex.status <= 299) {
      const flex = await responseFlex.json();
      dispatch({ type: 'GET_NOMINATIONS_REQUESTS_SUCCESS', payload: flex });
    } else {
      // Handle errors
      try {
        let jsonError = await responseFlex.json();
        console.error(jsonError);
        const errorMessage = jsonError?.message;
        dispatch(openErrorSnack(errorMessage));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      } catch (error: any) {
        console.error(error);
        dispatch(openErrorSnack(error?.message));
        dispatch({ type: 'GET_NOMINATIONS_REQUESTS_FAIL' });
      }
    }

    dispatch({ type: 'NOMINATIONS_TOGGLE_WARNING_DIALOG' });
  };
