import { useState } from 'react';

import { ClassNames } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import { useLocation } from 'react-router';

import DrawerLink from './DrawerLink';
import { LinkType } from './types/LinkType';
import { getDrawerLinks } from './utils/links';
import { useCompanyLogo } from './utils/logo';

const DrawerContent = (props: {
  isAuthenticated: boolean;
  user: {
    user_metadata?: {
      organization?: string;
    };
  };
  handleDrawerToggle: any;
  mobileOpen: boolean;
}): JSX.Element => {
  const { isAuthenticated, user, mobileOpen, handleDrawerToggle } = props;
  const location = useLocation();
  const currentLink = `/${location.pathname.split('/')[1]}`;
  const [activeLink, setActiveLink] = useState(currentLink);
  const { companyLogo } = useCompanyLogo(user);

  if (!isAuthenticated) return <></>;

  const linksToDisplay = getDrawerLinks({ isAuthenticated, user });

  return (
    <ClassNames>
      {() => (
        <div>
          <div style={{ padding: 10, maxHeight: 68, minHeight: 54 }}>
            <img
              src={companyLogo}
              alt=""
              style={{
                maxHeight: 28,
                float: 'left',
                position: 'absolute',
                top: 20,
                left: 61,
              }}
            />
            {mobileOpen && (
              <CloseIcon
                style={{ float: 'right', margin: 20, cursor: 'pointer' }}
                onClick={() => {
                  handleDrawerToggle();
                }}
              />
            )}
          </div>
          <List>
            {linksToDisplay.map((link) => (
              <DrawerLink
                key={link.to}
                link={link}
                activeLink={activeLink}
                onClick={(_link: LinkType) => {
                  handleDrawerToggle();
                  setActiveLink(_link.to);
                }}
              />
            ))}
          </List>
        </div>
      )}
    </ClassNames>
  );
};

export default DrawerContent;
