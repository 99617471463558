import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import { processInfoArray } from 'components/CreateOrUpdateProduct/utils/utils';
import { enums } from 'enums';
import { useFormikContext } from 'formik';

import CommonProductDetailsField from '../../Common/Fields/CommonField/CommonProductDetailsField';

const DynamicFieldsGroup = () => {
  const { values, setValues } = useFormikContext<any>();
  const { standards } = useCreateOrUpdateProductSlicesCombiner();
  const infoArray =
    standards && values && processInfoArray({ standards, values });

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} display="flex" alignItems="center">
        {infoArray.map((infoX: any, index: number) => {
          if (infoX.label) {
            if (infoX.type === enums.InfoTypes.SECTION) {
              return (
                <div
                  key={index}
                  style={{
                    width: '100%',
                    marginTop: 30,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <Divider />
                  <h3>{infoX.label}</h3>
                </div>
              );
            } else if (infoX.type === enums.InfoTypes.LIST) {
              return (
                <CommonProductDetailsField
                  key={index}
                  fieldType={enums.InfoTypes.LIST}
                  infoX={infoX}
                  index={index}
                  style={{ width: 300, marginTop: 10 }}
                />
              );
            } else if (infoX.type === enums.InfoTypes.LIST_MULTI) {
              return (
                <CommonProductDetailsField
                  key={index}
                  fieldType={enums.InfoTypes.LIST_MULTI}
                  infoX={infoX}
                  index={index}
                  values={values}
                  setValues={setValues}
                  style={{ width: 300, marginTop: 10 }}
                />
              );
            } else if (infoX.type === enums.InfoTypes.COLLECTION) {
              return (
                <CommonProductDetailsField
                  key={index}
                  fieldType={enums.InfoTypes.COLLECTION}
                  infoX={infoX}
                  index={index}
                  style={{ width: 300, marginTop: 10 }}
                />
              );
            } else if (infoX.type === enums.InfoTypes.COLLECTION_MULTI) {
              return (
                <CommonProductDetailsField
                  key={index}
                  fieldType={enums.InfoTypes.COLLECTION_MULTI}
                  infoX={infoX}
                  index={index}
                  values={values}
                  setValues={setValues}
                  style={{ width: 300, marginTop: 10 }}
                />
              );
            } else if (infoX.type === enums.InfoTypes.DATE) {
              return (
                <CommonProductDetailsField
                  key={index}
                  fieldType={enums.InfoTypes.DATE}
                  index={index}
                  infoX={infoX}
                  style={{ width: 300, marginTop: 10 }}
                />
              );
            } else if (infoX.type === enums.InfoTypes.NUMBER) {
              return (
                <CommonProductDetailsField
                  key={index}
                  fieldType={enums.InfoTypes.NUMBER}
                  index={index}
                  infoX={infoX}
                  style={{ width: 300, marginTop: 10 }}
                />
              );
            } else if (infoX.type === enums.InfoTypes.DYNAMIC_DATA) {
              return (
                <CommonProductDetailsField
                  key={index}
                  fieldType={enums.InfoTypes.DYNAMIC_DATA}
                  index={index}
                  infoX={infoX}
                  style={{ width: 300, marginTop: 10, marginBottom: 20 }}
                  withTooltip={false}
                />
              );
            } else {
              return (
                <CommonProductDetailsField
                  key={index}
                  index={index}
                  infoX={infoX}
                  style={{ width: 300, marginTop: 10 }}
                />
              );
            }
          }
        })}
      </Grid>
    </Grid>
  );
};

export default DynamicFieldsGroup;
